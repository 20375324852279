import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

const BlogDetail = () => {
  let obj: any = {
    title: "",
    preview_image: { file: "", name: "" },
    preview_description: "",
    date_added: "",
    blog_id: "",
    active: Boolean,
    content: [],
  };

  const [blog, setBlog] = useState(obj);
  const { id } = useParams();


  const getBlog = () => {
    axios
      .get(`https://api.multifarm.fi/jay_flamingo_random_6ix_vegas/get_blog`, { params: { blog_id: id } })
      .then((data) => {
        setBlog(data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getBlog();
  }, []);

  return (
    <>
        <div className="bg-grad pb-5">
      <div className="container mb-5 details">
        <div className="row justify-content-md-center">
          <div className="col-md-8">
            <div className="">
              <Link to="/blog">
                <a className="post-link"><span className="iconify bc" data-icon="lucide:chevron-left"></span> Back to Blog</a>
              </Link>
              <h1 className="post-title">{blog?.title}</h1>
              {/* <span className="post-date">Published on: {blog?.date_added}</span> */}
            </div>
          </div>
          <div className="col-md-12">
            <div className="post-image">
              <img src={blog?.preview_image?.file} className="w-100 h-100" alt={blog?.preview_image?.name} />
            </div>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-md-8">
            {blog?.content?.map((item: any, i: any) => {
              return (
                <>
                  {item.type === "h3" && <h3 className="content-h3">{item.content}</h3>}
                  {item.type === "text" && <p className="content-p">{item.content}</p>}
                  {item.type === "h2" && <h2 className="content-h2">{item.content}</h2>}
                  {item.type === "image" && <img className="w-100" src={item.content.file} alt={item.content.name} />}
                  {item.type === "list" && (
                    <ul>
                      {item.content.map((el: any, i: any) => {
                        return (
                          <React.Fragment key={i}>
                            <li>{el.content}</li>
                          </React.Fragment>
                        );
                      })}
                    </ul>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default BlogDetail;