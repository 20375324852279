import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import BlogDetail from './Components/BlogDetail';
// import CareerDetail from "./Components/CareerDetail";
import Footer from './Components/Footer';
import Header from './Components/Header';
import AboutsUs from './Pages/AboutsUs';
import Blogs from './Pages/Blogs';
import Careers from './Pages/Careers';
import Home from './Pages/Home';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blogs />} />
        <Route path="/blog/:id" element={<BlogDetail />} />
        <Route path="/career" element={<Careers />} />
        {/* <Route path="/career/:id" element={<CareerDetail />} /> */}
        <Route path="/about" element={<AboutsUs />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
