import React, {useEffect} from "react";


function AboutsUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="about-bg">
        <div className="container">
          <div className="about-heading mb-4">ABOUT MULTIFARM</div>
          <div className="about-content mb-5"><span>Mission:</span> Building Data Infrastructure for DeFi</div>
          <div className="about-card">
            <div className="about-content">
            We believe in the power of DeFi to transform finance and put value back into the hands of individuals. Our goal is to build the go-to platform for DeFi data and DAO tooling, by offering Treasury Dashboards, Yield Farming Dashboards and numerous other fully comprehensive and totally customizable solutions. We're a constantly growing technical team working at the confluence of finance, smart contracts and data science. Our team has a background from top-academic institutions as well as engineering expertise from big web 2.0 companies.
            </div>
          </div>

          <div className="trustedBy text-center">
            <h6>TEAM BACKGROUND</h6>
            <div className="d-flex flex-wrap gap-md-5 justify-content-md-center">
              <div className="trust-logo">
                <a href="javascript:void()">
                  <img src="assets/eth.svg" alt="" />
                </a>
              </div>
              <div className="trust-logo">
                <a href="javascript:void()">
                  <img src="assets/oracle.svg" className="img-1" alt="" />
                </a>
              </div>
              <div className="trust-logo">
                <a href="javascript:void()">
                  <img src="assets/tmu.svg" alt="" />
                </a>
              </div>
              <div className="trust-logo">
                <a href="javascript:void()">
                  <img src="assets/universt.svg" alt="" />
                </a>
              </div>
              <div className="trust-logo">
                <a href="javascript:void()">
                  <img src="assets/lmu.svg" alt="" />
                </a>
              </div>
              <div className="trust-logo">
                <a href="javascript:void()">
                  <img src="assets/barclays.svg" alt="" />
                </a>
              </div>
              <div className="trust-logo">
                <a href="javascript:void()">
                  <img src="assets/flashbots.svg" alt="" />
                </a>
              </div>
              <div className="trust-logo">
                <a href="javascript:void()">
                  <img src="assets/imperial.png" className="h-1" alt="" />
                </a>
              </div>
              <div className="trust-logo">
                <a href="javascript:void()">
                  <img src="assets/julius.png" className="h-2" alt="" />
                </a>
              </div>
              <div className="trust-logo">
                <a href="javascript:void()">
                  <img src="assets/hsbc.png" className="h-3" alt="" />
                </a>
              </div>
              <div className="trust-logo h-3">
                <a href="javascript:void()">
                  <img src="assets/consensys.png" className="h-4" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutsUs