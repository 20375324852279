import { useEffect } from 'react';
// import axios from "axios";
import { Link } from 'react-router-dom';

function Careers() {
  // const [careers, setTCareers] = useState([])

  // const getTCareers = () => {
  //   axios
  //     .get("https://api.multifarm.fi/jay_flamingo_random_6ix_vegas/get_careers")
  //     .then((data) => {
  //       setTCareers(data.data)
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  // useEffect(() => {
  //   getTCareers();
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="career">
        <div className="container">
          <div className="career-width">
            <div className="career-heading mb-3">MULTIFARM CAREERS</div>
            <div className="career-content mb-5">
              Be a part of a fast-growing team and join us on our journey to
              build the <br /> future of finance.
            </div>
            <div className="mb-md-5 pb-5">
              {/* {careers.map((career: any) => (
                <Link
                  to={`/career/${career.career_id}`}
                  className="career-card"
                >
                  <div>
                    <div className="heading mb-4"> {career?.title}</div>
                    <div className="remote-text">{career?.location}</div>
                  </div>
                  <a href="javascript:void(0)">
                    <img src="assets/arrow-right.svg" alt="" />
                  </a>
                </Link>
              ))} */}
            </div>
            <div className="work-border">
              <div className="work-card">
                <div className="work-content">
                  <div className="heading mb-4">WANT TO WORK WITH US?</div>
                  <a href="mailto:info@multifarm.fi" className="btnOutline">
                    Contact Us
                  </a>
                </div>
                <div className="logo-section">
                  <img src="assets/logo.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Careers;
