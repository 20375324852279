import React, {useState} from "react";

import Modal from 'react-bootstrap/Modal';
import { Link,NavLink } from 'react-router-dom';
import { validateName } from "../utils/validation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Header = () => {
  const [success, setSuccess] = useState('');
  const [show, setShow] = useState(false);
  const [heading, setHeading] = useState("");

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [privacy, setPrivacy] = useState<any>(false);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const validateForm = ({ name, email, privacy }: any) => {
    let valid = true;
    if (!validateName(name)) {
      valid = false;
      toast.error("Enter a valid name of DAO/Company");
    }
    if (!email) {
      valid = false;
      toast.error("Enter contact details");
    }
    if (!privacy) {
      valid = false;
      toast.error("Check Privacy Policy checkbox");
    }
    return valid;
  };

  const submitForm = async () => {
    const data = { email, name, message };
    const valid = validateForm({ ...data, privacy });

    if (valid) {
      try {
        await data;
        let res = axios.post("https://api.multifarm.fi/jay_flamingo_random_6ix_vegas/order_demo", data);
        const response = await res;
        console.log(response);
        setSuccess(response.data.success)
      } catch (err) {
        console.error(err);
        toast.error("Oops error!");
        // throw new Error(err);
      } finally {
        // handleClose ()
      }
    }
  };
  const homeNav = () => {
		if (document.body.classList.contains("home-sm")) {
			document.body.classList.remove("home-sm");
		} else {
			document.body.classList.add("home-sm");
		}
	};

  const scrollTreasury = () => {
    const section:any = document.querySelector( '#treasury' );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };
  const scrollCustom = () => {
    const section:any = document.querySelector( '#custom' );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };
  const scrollFinancial = () => {
    const section:any = document.querySelector( '#financial' );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };
  const scrollMultifarm = () => {
    const section:any = document.querySelector( '#multifarm' );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };
  
  return (
        <div>
      <nav className="navbar navbar-expand-lg transition-all py-4" id="navbar-example2">
        <div className="container">
          <Link to="/" className="navbar-brand"><img src="assets/logo-dark.svg" alt="" /></Link>
          <button className="navbar-toggler" type="button" onClick={() => {homeNav()}}>
          <span className="iconify" data-icon="humbleicons:bars"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="d-md-none d-block text-end p-4">
              <a href="javascript:void(0)" onClick={() => {homeNav()}} className='cross-btn'><span className="iconify" data-icon="akar-icons:cross"></span></a>
            </div>
            <ul className="navbar-nav mx-auto px-md-0 px-5 mb-2 align-items-lg-center gap-4 mb-lg-0">
              <li className="nav-item">
              <Link to={""} className="nav-link position-relative" >Products
              <div className="product-dropdown">
               <div className="products">
               <h6>Product Suite</h6>
                <ul>
                  <li><img src="assets/d1.svg" alt="" /> <a href="javascript:void()" onClick={scrollTreasury}>Treasury Dashboard</a></li>
                  <li><img src="assets/d2.svg" alt="" /> <a href="javascript:void()" onClick={scrollCustom}>Custom Dashboards</a></li>
                  <li><img src="assets/d3.svg" alt="" /> <a href="javascript:void()" onClick={scrollFinancial}>Financial Modelling</a></li>
                  <li><img src="assets/d4.svg" alt="" /> <a href="javascript:void()" onClick={scrollMultifarm}>Community DeFi Dashbaord</a></li>
                </ul>
               </div>
              </div>
              </Link>
              </li>
              <li className="nav-item">
              <NavLink to="/blog" className="nav-link" onClick={() => {homeNav()}}>Blog</NavLink>
              </li>
              <li className="nav-item">
              <NavLink to="/career" className="nav-link" onClick={() => {homeNav()}}>Careers</NavLink>
              </li>
              <li className="nav-item">
              <NavLink to="/about" className="nav-link" onClick={() => {homeNav()}}>About Us</NavLink>
              </li>
            </ul>
            <div className="ms-lg-5 px-lg-0 px-5">
              <a href="javascript:void(0)" onClick={() => {handleShow(); setHeading('SCHEDULE A DEMO')}} className='connect-outline-btn mt-md-0 mt-4'>Learn more</a>
            </div>
          </div>
        </div>
      </nav>

      <ToastContainer />
        <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        { success === "" &&<Modal.Body>
          <div className="modal-heading">{heading}</div>
          <div className="input-box mb-3">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name of DAO/Company"
            />
          </div>
          <div className="input-box mb-3">
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Contact (Discord, Telegram, E-Mail)"
            />
          </div>
          <div className="input-box mb-4">
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Message (not mandatory)"
            ></textarea>
          </div>
          <div className="d-flex align-items-center gap-3 mb-4">
            <input type="checkbox" checked={privacy} onChange={setPrivacy} name="" id="" />
            <div className="policy-text">
              I have read and agree to the{" "}
              <a href="https://app.multifarm.fi/privacy" target={"_blank"}>
                {" "}
                Privacy Policy{" "}
              </a>
            </div>
          </div>
          <button
            type="button"
            onClick={() => submitForm()}
            className="submit-btn"
          >
            Submit
          </button>
        </Modal.Body>}

        { success && <Modal.Body>
          <div className="modal-heading text-none">Thank you for submitting the form!</div>
          <div className="text-center">
          <button type="button" onClick={() => handleClose()} className="submit-btn">
            close
          </button>
          </div>
        </Modal.Body>}
      </Modal>
    </div>
  )
}

export default Header