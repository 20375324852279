import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Modal from "react-bootstrap/Modal";

import "swiper/css";
import "swiper/css/pagination";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Pagination } from "swiper";
import { validateName } from "../utils/validation";
import axios from "axios";

const Home = () => {

  const [show, setShow] = useState(false);
  const [heading, setHeading] = useState("");
  const [success, setSuccess] = useState('');

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [privacy, setPrivacy] = useState<any>(false);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const validateForm = ({ name, email, privacy }: any) => {
    let valid = true;
    if (!validateName(name)) {
      valid = false;
      toast.error("Enter a valid name of DAO/Company");
    }
    if (!email) {
      valid = false;
      toast.error("Enter contact details");
    }
    if (!privacy) {
      valid = false;
      toast.error("Check Privacy Policy checkbox");
    }
    return valid;
  };

  const submitForm = async () => {
    const data = { email, name, message };
    const valid = validateForm({ ...data, privacy });

    if (valid) {
      try {
        await data;
        let res = axios.post("https://api.multifarm.fi/jay_flamingo_random_6ix_vegas/order_demo", data);
        const response = await res;
        setSuccess(response.data.success)
      } catch (err) {
        console.error(err);
        toast.error("Oops error!");
        // throw new Error(err);
      } finally {
        // handleClose ()
      }
    }
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div >
      {/* Banner  */}
      <section className="banner" >
        <div className="container">
          <div className="pt-5 mb-5 text-center banner-content">
            <h1>
              The Leading Analytics Partner <br /> for DAOs and DEFI Protocols
            </h1>
            <div className="col-lg-7 mx-auto">
              <p className="mb-4">Multifarm offers custom dashboards natively integrated into your protocols website</p>
              <div className="mb-5">
                <a href="javascript:void(0)" className="connect-btn" onClick={() => {handleShow(); setHeading('SCHEDULE A DEMO')}}>
                  Schedule a demo
                </a>
              </div>
            </div>
            <div className="overflow-hidden">
              <img src="assets/Banner.png" className="img-fluid" loading="lazy" />
            </div>
          </div>

          <div className="trustedBy text-center mb-5">
            <h6>Trusted By</h6>
            <div className="d-flex flex-wrap gap-md-5 justify-content-center px-lg-5">
              <div className="trust-logo">
                <a href="javascript:void()">
                  <img src="assets/t1.svg" alt="" />
                </a>
              </div>
              <div className="trust-logo">
                <a href="javascript:void()">
                  <img src="assets/t2.png" height={40} alt="" />
                </a>
              </div>
              <div className="trust-logo">
                <a href="javascript:void()">
                  <img src="assets/t3.svg" alt="" />
                </a>
              </div>
              <div className="trust-logo">
                <a href="javascript:void()">
                  <img src="assets/t4.png" className="t-4" alt="" />
                </a>
              </div>
              <div className="trust-logo">
                <a href="javascript:void()">
                  <img src="assets/t5.png" className="t-5" alt="" />
                </a>
              </div>
              <div className="trust-logo blend">
                <a href="javascript:void()">
                  <img src="assets/t15.png" height={40} alt="" />
                </a>
              </div>
             
              <div className="trust-logo">
                <a href="javascript:void()">
                  <img src="assets/t7.svg" alt="" />
                </a>
              </div>
              <div className="trust-logo">
                <a href="javascript:void()">
                  <img src="assets/t8.png" height={40} alt="" />
                </a>
              </div>
              <div className="trust-logo">
                <a href="javascript:void()">
                  <img src="assets/t9.png" className="t-9" alt="" />
                </a>
              </div>
              <div className="trust-logo">
                <a href="javascript:void()">
                  <img src="assets/t10.svg" alt="" />
                </a>
              </div>
              <div className="trust-logo blend1">
                <a href="javascript:void()">
                  <img src="assets/t11.png" className="t-11" alt="" />
                </a>
              </div>
              <div className="trust-logo">
                <a href="javascript:void()">
                  <img src="assets/t12.svg" alt="" />
                </a>
              </div>
              <div className="trust-logo blend">
                <a href="javascript:void()">
                  <img src="assets/t13.png" height={50} alt="" />
                </a>
              </div>
              <div className="trust-logo">
                <a href="javascript:void()">
                  <img src="assets/t14.svg" height={40} alt="" />
                </a>
              </div>
              <div className="trust-logo">
                <a href="javascript:void()">
                  <img src="assets/t6.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="multi-dashboard">
            <h4>Multifarm Dashboard Metrics</h4>
            <div className="row px-lg-4">
              <div className="col-md-3 mb-md-0 mb-4 pe-lg-5">
                <h5>300k+</h5>
                <p>We are serving 300k+ monthly active users through our dashboards, this number will go up exponentially once more dashboards go live</p>
              </div>
              <div className="col-md-3 mb-md-0 mb-4 pe-lg-5">
                <h5>20+</h5>
                <p>We have partnered with 20+ DAOs and Protocols and building out custom dashboards and analytics solutions for them.</p>
              </div>
              <div className="col-md-3 mb-md-0 mb-4 pe-lg-5">
                <h5>300m</h5>
                <p>A total of 300 million USD treasury value is managed by the Multifarm treasury dashboard technology</p>
              </div>
              <div className="col-md-3 mb-md-0 mb-4">
                <h5>300+</h5>
                <p>Helping our dashboard clients to get cross-chain portfolio and yield data across 300+ protocols and 15+ different blockchains</p>
              </div>
            </div>
            <div className="text-center py-5">
              <a href="javascript:void(0)" className="connect-btn" onClick={() => {handleShow(); setHeading('SCHEDULE A DEMO')}}>
                Schedule a demo
              </a>
            </div>
          </div>
        </div>
        <div className="bg-dashboard">
          <div className="container">
            <div className="box" id="treasury">
              <div className="row">
                <div className="col-md-5">
                  <div className="icon">
                    <img src="assets/d1.svg" alt="" />
                  </div>
                  <h2>Treasury Dashboard Solution</h2>
                  <p>
                    Multifarm’s Treasury Dashboard is one of the most comprehensive & customizable in the DeFi space. Our dashboards make complex treasuries comprehensible & transparent which in turn
                    helps establish trust with both community & investors containing all key metrics that DAOs & DeFi protocols require such as:
                  </p>
                  <ul>
                    <li>Yield</li>
                    <li>Revenue Streams</li>
                    <li>Assets & Strategies</li>
                  </ul>
                </div>
                <div className="col-md-7">
                  <img src="assets/d-1.png" className="w-100" alt="" />
                </div>
              </div>
            </div>

            <div className="box" id="custom">
              <div className="row">
                <div className="col-md-5">
                  <div className="icon">
                    <img src="assets/d2.svg" alt="" />
                  </div>
                  <h2>Custom Dashboards</h2>
                  <p>Building out custom dasboards for your protocols needs directly on your protocols website. We have expertise in:</p>
                  <ul>
                    <li>Yield Dashboards</li>
                    <li>Risk Dashboards</li>
                    <li>NFT Dashboards</li>
                    <li>Protocol Metrics Dashboards</li>
                  </ul>
                </div>
                <div className="col-md-7">
                  <img src="assets/d-2.png" className="w-100" alt="" />
                </div>
              </div>
            </div>

            <div className="box" id="financial">
              <div className="row">
                <div className="col-md-5">
                  <div className="icon">
                    <img src="assets/d3.svg" alt="" />
                  </div>
                  <h2>Financial Modelling</h2>
                  <p>Leveraging our historical DeFi data across multiple blockchains, we are offering Financial Optimization for DAOs. This includes:</p>
                  <ul>
                    <li>Bribing Optimization</li>
                    <li>Emissions Optimization</li>
                    <li>Treasury Optimization</li>
                  </ul>
                </div>
                <div className="col-md-7">
                  <img src="assets/d-3.png" className="w-100" alt="" />
                </div>
              </div>
            </div>

            <div className="box mb-0" id="multifarm">
              <div className="row">
                <div className="col-md-5">
                  <div className="icon">
                    <img src="assets/d4.svg" alt="" />
                  </div>
                  <h2>Multifarm Community DeFi Platform</h2>
                  <p>Our yield farming data aggregation platform compares hundreds of farms and thousands of assets to help users find the best rates for their respective assets.</p>
                  <a href="https://app.multifarm.fi/farms?pg=1&blockchain=&sort=tvlStaked&sort_order=desc&tvl_min=1000000&search=" target={"_blank"} className="connect-btn">
                    Launch App
                  </a>
                </div>
                <div className="col-md-7">
                  <img src="assets/d-4.png" className="w-100" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </section>
      <div className="testimonial">
        <div className="moon"><img src="assets/moon.svg" alt="" /></div>
          <h3>Testimonials</h3>
          <div className="container-fluid">
                    <Swiper
                  slidesPerView={1}
                  spaceBetween={10}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 50,
                    },
                  }}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                      <SwiperSlide>
                            <div className="review-card">
                                <p>
                                  "We’re delighted with the work completed by the Multifarm team on the Exponential Capital dashboard. The Multifarm team worked efficiently, understood our needs and requirements
                                  and delivered promptly, to deadline and within budget."
                                </p>
                                <div className="review-ic">
                                  <img src="assets/user.svg" alt="" />
                                </div>
                              </div>
                      </SwiperSlide>
                      <SwiperSlide>
                            <div className="review-card">
                                <p>
                                "Transparency is key to Sphere. That is why we contacted Multifarm to create a dashboard showing our revenue generation. With a project centered around creating revenue for users, Multifarm offers everything we need."
                                </p>
                                <div className="review-ic">
                                  <img src="assets/user1.svg" alt="" />
                                </div>
                              </div>
                      </SwiperSlide>
                      <SwiperSlide>
                            <div className="review-card">
                                <p>
                                "Would recommend, Multifarm has a very experienced team with diverse set of skills! They helped us every step of the way with our integrations."
                                </p>
                                <div className="review-ic">
                                  <img src="assets/user2.svg" alt="" />
                                </div>
                              </div>
                      </SwiperSlide>
                </Swiper>
          </div>
        </div>
        <div className="test-btn">
          <a href="javascript:void(0)" className="connect-btn demo" onClick={() => {handleShow(); setHeading('SCHEDULE A DEMO')}}>
            Schedule a demo
          </a>
        </div>
        <div className="container mb-5 pb-md-5">
        <div className="work-border home">
              <div className="work-card">
                <div className="work-content">
                  <div className="heading text-start mb-4">
                  WANT TO WORK WITH US?
                  </div>
                  <div className="d-flex align-items-center gap-3 flex-wrap">
                  <a href="https://discord.gg/aE3DTfXnGC" target={"_blank"} className="btnOutline">Talk to us in Discord</a>
                  <div className="or-text">or</div>
                  <a href="javascript:void(0)" className="btnOutline" onClick={() => {setHeading('Send Email'); handleShow()}}>Send Email</a>
                  </div>
                </div>
                <div className="logo-section">
                  <img src="assets/logo.svg" alt="" />
                </div>
              </div>
            </div>
        </div>
       
      <ToastContainer />
        <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>

        { success === "" &&<Modal.Body>
          <div className="modal-heading">{heading}</div>
          <div className="input-box mb-3">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name of DAO/Company"
            />
          </div>
          <div className="input-box mb-3">
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Contact (Discord, Telegram, E-Mail)"
            />
          </div>
          <div className="input-box mb-4">
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Message (not mandatory)"
            ></textarea>
          </div>
          <div className="d-flex align-items-center gap-3 mb-4">
            <input type="checkbox" checked={privacy} onChange={setPrivacy} name="" id="" />
            <div className="policy-text">
              I have read and agree to the{" "}
              <a href="https://app.multifarm.fi/privacy" target={"_blank"}>
                {" "}
                Privacy Policy{" "}
              </a>
            </div>
          </div>
          <button
            type="button"
            onClick={() => submitForm()}
            className="submit-btn"
          >
            Submit
          </button>
        </Modal.Body>}

        { success && <Modal.Body>
          <div className="modal-heading text-none">Thank you for submitting the form!</div>
          <div className="text-center">
          <button type="button" onClick={() => handleClose()} className="submit-btn">
            close
          </button>
          </div>
        </Modal.Body>}
        
      </Modal>
    </div>
  );
};

export default Home;
