import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const scrollTreasury = () => {
    const section: any = document.querySelector("#treasury");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  return (
    <>
      <div className="footer">
        <div className="container-fluid">
          <div className="logo mb-5">
            <img src="assets/logo-light.svg" alt="" />
          </div>
          <div className="foot-para mb-3 d-md-none d-block"> Multifarm is
            a DeFi Analytics Company that builds dashboards for other protocols
            and DAOs. By starting out and building the most comprehensive yield
            farming dashboard in the DeFi industry we discovered the need to
            support other protocols in extracting data from the blockchain and
            making it visible in the form of different types of dashboards.
            Multifarm’s dashboards are easy to understand, fully customizable
            and benefit protocols and users alike.
          </div>
          <div className="d-md-flex justify-content-between align-items-center mb-5">
            <div className="social-icons d-flex align-items-center gap-3 ">
              <a
                href="https://twitter.com/MultifarmFi"
                target={"_blank"}
                rel="noreferrer"
              >
                <span className="iconify" data-icon="mdi:twitter"></span>
              </a>
              <a
                href="https://discord.gg/aE3DTfXnGC"
                target={"_blank"}
                rel="noreferrer"
              >
                <span className="iconify" data-icon="ic:outline-discord"></span>
              </a>
              <a
                href="https://medium.com/@multifarm_fi"
                target={"_blank"}
                rel="noreferrer"
              >
                <span className="iconify" data-icon="carbon:logo-medium"></span>
              </a>
            </div>
            <div className="nav-links d-md-flex align-items-center gap-3 my-md-0 my-4">
              <div className="mb-2 mb-md-0">
                <a
                  href="https://app.multifarm.fi/disclaimer"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Disclaimer
                </a>
              </div>
              <div className="mb-2 mb-md-0">
                <a
                  href="https://app.multifarm.fi/privacy"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Privacy policy
                </a>
              </div>
              <div className="mb-2 mb-md-0">
                <a
                  href="https://drive.google.com/drive/u/0/folders/1kGX8lo7zAynyMt4Pqego9r37tGgPFIUU"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Media kit
                </a>
              </div>
            </div>
          </div>
          <div className="d-md-flex justify-content-between align-items-center">
            <div className="foot-para d-md-block d-none">
               Multifarm is a DeFi Analytics Company that builds dashboards for other
              protocols and DAOs. By starting out and building the most
              comprehensive yield farming dashboard in the DeFi industry we
              discovered the need to support other protocols in extracting data
              from the blockchain and making it visible in the form of different
              types of dashboards. Multifarm’s dashboards are easy to
              understand, fully customizable and benefit protocols and users
              alike.
            </div>

            <div className="w-35">
              <div className="copyright-text text-md-end">
                © 2024 Multifarm.fi. A brand of Nexus Quant Labs EOOD. All Rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
