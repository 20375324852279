import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';

function Blogs() {

    const [blogs, setBlogs] = useState([])

  const getBlogs = () => {
    axios
      .get("https://api.multifarm.fi/jay_flamingo_random_6ix_vegas/get_blogs")
      .then((data) => {
        setBlogs(data.data.blogs)
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getBlogs();
  }, []);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="blog-bg">

        <div className="browse-section py-5">
          <div className="container">
            <div className="browse-heading mb-3">Browse the blog</div>
            <div className="browse-text mb-5">
              Want to learn more? Click on a category below for more from the
              blog.
            </div>
            <div className="row mt-5">
            {blogs.map((blog:any)=>
              <div className="col-lg-4 col-6 px-md-4 mb-4">
                    <Link to={`/blog/${blog.blog_id}`}>
                    <div className="blog-card">
                      <div className="blog-img">
                        <img
                          src={blog?.preview_image?.file}
                          className="w-100"
                          alt={blog?.preview_image?.name}
                        />
                      </div>
                      <div className="card-content">
                        <div className="title">
                        {blog?.title}
                        </div>
                      </div>
                    </div>
                  </Link>
              </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blogs;
